import React from 'react'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { Route } from 'constants/common/routes'
import {
  CalendarIcon,
  CreditCardIcon,
  RunIcon,
  SettingsIcon,
  SignOutIcon,
  TicketIcon,
  UserIcon,
} from 'components/icons'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'

type NavbarMenuProps = {
  isCustomer: boolean
  isReviewed: boolean
  className?: string
}

const DashboardAuthorizedUserMenu = ({ isCustomer, isReviewed, className }: NavbarMenuProps) => {
  const { push } = useRouter()

  return (
    <div className={className}>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <UserIcon className="hover:text-brand-mint h-9 w-9" />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            disabled={!isReviewed && !isCustomer}
            onClick={() => {
              void push(isCustomer ? Route.MemberCalendar : Route.Calendar)
            }}
          >
            <div className="flex items-center gap-2">
              <CalendarIcon className="h-6 w-6" />
              <div>Calendar</div>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            disabled={!isReviewed && !isCustomer}
            onClick={() => {
              void push(isCustomer ? Route.MyMembershipsMember : Route.MyMembershipsAthlete)
            }}
          >
            <div className="flex items-center gap-2">
              <TicketIcon className="h-6 w-6" />
              <div>Memberships</div>
            </div>
          </DropdownMenuItem>
          {!isCustomer && (
            <>
              <DropdownMenuItem
                disabled={!isReviewed}
                onClick={() => {
                  void push(Route.CoachingPreferences)
                }}
              >
                <div className="flex items-center gap-2">
                  <RunIcon className="h-6 w-6" />
                  <div>Coaching preferences</div>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                disabled={!isReviewed}
                onClick={() => {
                  void push(Route.ProfileSettings)
                }}
              >
                <div className="flex items-center gap-2">
                  <UserIcon className="h-6 w-6" />
                  <div>Profile settings</div>
                </div>
              </DropdownMenuItem>
            </>
          )}

          {!isCustomer && (
            <DropdownMenuItem
              disabled={!isReviewed}
              onClick={() => {
                void push('https://dashboard.stripe.com/login')
              }}
            >
              <div className="flex items-center gap-2">
                <CreditCardIcon className="h-6 w-6" />
                <div>Stripe account</div>
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            disabled={!isReviewed && !isCustomer}
            onClick={() => {
              void push(isCustomer ? Route.MemberAccountSettings : Route.AthleteAccountSettings)
            }}
          >
            <div className="flex items-center gap-2">
              <SettingsIcon className="h-6 w-6" />
              <div>Account settings</div>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              void signOut()
            }}
          >
            <div className="flex items-center gap-2">
              <SignOutIcon className="h-6 w-6" />
              <div>Sign out</div>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default DashboardAuthorizedUserMenu
