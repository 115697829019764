import { SVGProps } from 'react'

export const FacebookColorfulIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M30.6668 16.0007C30.6668 7.90047 24.1003 1.33398 16.0002 1.33398C7.89998 1.33398 1.3335 7.90047 1.3335 16.0007C1.3335 23.3211 6.69686 29.3889 13.7085 30.4891V20.2402H9.98454V16.0007H13.7085V12.7694C13.7085 9.09357 15.8982 7.06315 19.2483 7.06315C20.8525 7.06315 22.5314 7.34961 22.5314 7.34961V10.959H20.682C18.8602 10.959 18.2918 12.0896 18.2918 13.2507V16.0007H22.3595L21.7093 20.2402H18.2918V30.4891C25.3035 29.3889 30.6668 23.3211 30.6668 16.0007Z"
      fill="#1877F2"
    />
    <path
      d="M21.7093 20.2402L22.3595 16.0007H18.2918V13.2507C18.2918 12.0908 18.8602 10.959 20.682 10.959H22.5314V7.34961C22.5314 7.34961 20.8525 7.06315 19.2483 7.06315C15.8982 7.06315 13.7085 9.09357 13.7085 12.7694V16.0007H9.98454V20.2402H13.7085V30.4891C15.227 30.7267 16.7733 30.7267 18.2918 30.4891V20.2402H21.7093Z"
      fill="white"
    />
  </svg>
)
