export const DEFAULT_TIMEZONE = 'America/Los_Angeles'
export const NA = 'N/A'
export const FACEBOOK_URL = 'https://www.facebook.com/theoffseasonusa'
export const INSTAGRAM_URL = 'https://www.instagram.com/theoffseasonusa'
export const INSTAGRAM_BASE_URL = 'https://www.instagram.com/'
export const TWITTER_BASE_URL = 'https://www.twitter.com/'
export const OFFSEASON_SUPPORT_EMAIL = 'support@theoffseason.io'

export const DEFAULT_CURRENCY = 'usd'

export const timeSlotsSelectValues = [
  { value: '06:00', name: '6:00 AM' },
  { value: '06:30', name: '6:30 AM' },
  { value: '07:00', name: '7:00 AM' },
  { value: '07:30', name: '7:30 AM' },
  { value: '08:00', name: '8:00 AM' },
  { value: '08:30', name: '8:30 AM' },
  { value: '09:00', name: '9:00 AM' },
  { value: '09:30', name: '9:30 AM' },
  { value: '10:00', name: '10:00 AM' },
  { value: '10:30', name: '10:30 AM' },
  { value: '11:00', name: '11:00 AM' },
  { value: '11:30', name: '11:30 AM' },
  { value: '12:00', name: '12:00 PM' },
  { value: '12:30', name: '12:30 PM' },
  { value: '13:00', name: '1:00 PM' },
  { value: '13:30', name: '1:30 PM' },
  { value: '14:00', name: '2:00 PM' },
  { value: '14:30', name: '2:30 PM' },
  { value: '15:00', name: '3:00 PM' },
  { value: '15:30', name: '3:30 PM' },
  { value: '16:00', name: '4:00 PM' },
  { value: '16:30', name: '4:30 PM' },
  { value: '17:00', name: '5:00 PM' },
  { value: '17:30', name: '5:30 PM' },
  { value: '18:00', name: '6:00 PM' },
  { value: '18:30', name: '6:30 PM' },
  { value: '19:00', name: '7:00 PM' },
  { value: '19:30', name: '7:30 PM' },
  { value: '20:00', name: '8:00 PM' },
  { value: '20:30', name: '8:30 PM' },
  { value: '21:00', name: '9:00 PM' },
]

export const MINIMAL_BOOKING_ADVANCE_HOURS = 24
export const MAXIMAL_REFUND_ADVANCE_HOURS = 48

export const bookingTypeMapper = {
  single: 'One-on-one',
  group: 'Group training',
  athletePass: 'Athlete pass',
} as const

export const OCCUPIED_TIMESLOT_TEXT = 'You already have the appointment at this time slot.'
