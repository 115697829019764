import React, { useRef } from 'react'
import { useMedia } from 'react-use'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { cn } from 'lib/utils'
import { OFFSEASON_SUPPORT_EMAIL } from 'constants/common'
import { Route } from 'constants/common/routes'
import ExternalLink from 'components/common/ExternalLink'
import DashboardAuthorizedNavbarMenu from 'components/navbar/DashboardAuthorizedNavbarMenu'
import { Navbar } from 'components/navbar/Navbar'
import { buttonVariants } from 'components/ui/button'
import NavbarNavigation from './NavbarNavigation'

type DashboardAuthorizedNavbarProps = {
  className?: string
  unreadCount: number
  notificationBar?: DatasourceItem[]
}

const DashboardAuthorizedNavbar = ({
  className,
  unreadCount,
  notificationBar,
}: DashboardAuthorizedNavbarProps) => {
  const isSm = useMedia('(max-width: 767px)', false)

  const needHelpRef = useRef<HTMLAnchorElement>(null)

  return (
    <Navbar
      isSticky={!isSm}
      logoHref={Route.Base}
      className={className}
      navigation={<NavbarNavigation className="flex-1 hidden 2xl:flex" />}
      actionButtons={
        <div className="flex-row items-center justify-start py-0 px-2 gap-[6px] lg:flex hidden">
          <ExternalLink
            ref={needHelpRef}
            href={`mailto:${OFFSEASON_SUPPORT_EMAIL}?subject=Support`}
            className={cn(buttonVariants({ variant: 'ghost', colorType: 'white' }))}
          >
            Need help?
          </ExternalLink>
        </div>
      }
      menu={<DashboardAuthorizedNavbarMenu unreadCount={unreadCount} />}
      notificationBar={notificationBar}
    />
  )
}

export default DashboardAuthorizedNavbar
