import React, { PropsWithChildren } from 'react'

const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>>
>(({ children, ...props }, ref) => (
  <a {...props} target="_blank" rel="noopener noreferrer" ref={ref}>
    {children}
  </a>
))

export default ExternalLink
