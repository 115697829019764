import React from 'react'
import { useMedia } from 'react-use'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { UserIcon } from 'components/icons'
import { LinkConfig } from 'components/navbar/constants'
import MobileNavigationMenu from 'components/navbar/MobileNavigationMenu'
import NavbarMenu from 'components/navbar/NavbarDropdownMenu'
import { buttonVariants } from 'components/ui/button'

const UnauthorizedNavbarMenu = () => {
  const isMaxSm = useMedia('(max-width: 640px)', false)
  const isMaxMd = useMedia('(max-width: 767px)', false)
  const isMaxXl = useMedia('(max-width: 1279px), false')
  const isMax2Xl = useMedia('(max-width: 1535px)', false)
  const router = useRouter()
  return isMaxSm ? (
    <MobileNavigationMenu
      links={LinkConfig}
      // eslint-disable-next-line react/no-unstable-nested-components
      buttons={(close) => (
        <>
          <Link
            href={Route.SignIn}
            className={cn(
              buttonVariants({ variant: 'link', colorType: 'white' }),
              'uppercase self-center',
            )}
            onClick={close}
          >
            <UserIcon />
            Sign In
          </Link>
          <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
            <Link
              href={Route.CreateAccount}
              className={cn(
                buttonVariants({ variant: 'outline', size: 'lg' }),
                'uppercase font-semibold w-full',
              )}
              onClick={close}
            >
              Become a coach
            </Link>

            <Link
              href={Route.Athletes}
              className={cn(
                buttonVariants({ colorType: 'white', size: 'lg' }),
                'uppercase font-bold w-full text-lg',
              )}
              onClick={close}
            >
              Book athlete
            </Link>
          </div>
        </>
      )}
    />
  ) : (
    <NavbarMenu
      className="flex 2xl:hidden"
      actions={[
        {
          isVisible: isMax2Xl,
          name: 'Athletes',
          onClick: () => {
            void router.push(Route.Athletes)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'Clinics',
          onClick: () => {
            void router.push(Route.Clinics)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'How it works',
          onClick: () => {
            void router.push(Route.HowItWorks)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'Contact',
          onClick: () => {
            void router.push(Route.Contact)
          },
        },
        {
          isVisible: isMaxMd,
          name: 'Become a coach',
          onClick: () => {
            void router.push(Route.CreateAccount)
          },
        },
        {
          isVisible: isMaxXl,
          name: 'Sign In',
          onClick: () => {
            void router.push(Route.SignIn)
          },
        },
      ]}
    />
  )
}

export default UnauthorizedNavbarMenu
