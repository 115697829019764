import React from 'react'
import { useMedia } from 'react-use'
import { Role } from '@prisma/client'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import { Route } from 'constants/common/routes'
import { MessageIcon } from 'components/icons'
import LandingPageAuthorizedNavbarMenu from 'components/navbar/LandingPageAuthorizedNavbarMenu'
import { Navbar } from 'components/navbar/Navbar'
import NavbarNavigation from 'components/navbar/NavbarNavigation'
import NavbarUserButton from 'components/navbar/NavbarUserButton'
import { buttonVariants } from 'components/ui/button'

type LandingPageAuthorizedNavbarProps = {
  className?: string
  unreadCount: number
  notificationBar?: DatasourceItem[]
}

const LandingPageAuthorizedNavbar = ({
  className,
  unreadCount,
  notificationBar,
}: LandingPageAuthorizedNavbarProps) => {
  const { data } = useSession()
  const isMaxSm = useMedia('(max-width: 640px)', false)

  const role = data?.user.role

  const roleToActionButtons: { [key in Role]: React.ReactNode } = {
    [Role.Member]: (
      <div className="flex flex-row items-center justify-center uppercase gap-4">
        <Link
          href={Route.Athletes}
          className={cn(
            buttonVariants({ colorType: 'white', size: isMaxSm ? 'default' : 'xl' }),
            'uppercase font-bold md:flex hidden',
          )}
        >
          Book athlete
        </Link>
        <Link href={Route.Messages}>
          <MessageIcon
            hasNewMessage={!!unreadCount}
            className="hover:text-brand-mint h-8 w-8 xs:h-9 xs:w-9"
          />
        </Link>
        <NavbarUserButton name={data?.user.name} />
      </div>
    ),
    [Role.Athlete]: (
      <div className="flex flex-row items-center justify-center uppercase gap-4">
        <Link href={Route.Messages}>
          <MessageIcon
            hasNewMessage={!!unreadCount}
            className="hover:text-brand-mint h-8 w-8 xs:h-9 xs:w-9"
          />
        </Link>
        <NavbarUserButton name={data?.user.name} />
      </div>
    ),
    [Role.Admin]: null,
  }

  return (
    <Navbar
      isSticky
      logoHref={Route.Base}
      className={className}
      navigation={<NavbarNavigation className="flex-1 hidden xl:flex" />}
      actionButtons={role && roleToActionButtons[role]}
      menu={<LandingPageAuthorizedNavbarMenu />}
      notificationBar={notificationBar}
    />
  )
}

export default LandingPageAuthorizedNavbar
