import React from 'react'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { useGetUnreadCountQuery } from 'apollo/generated/graphqlClient'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import DashboardAuthorizedNavbar from 'components/navbar/DashboardAuthorizedNavbar'
import LandingPageAuthorizedNavbar from 'components/navbar/LandingPageAuthorizedNavbar'

type AuthorizedNavbarProps = {
  className?: string
  notificationBar?: DatasourceItem[]
}

const AuthorizedNavbar = ({ className, notificationBar }: AuthorizedNavbarProps) => {
  const router = useRouter()

  const { data: unreadCount } = useGetUnreadCountQuery({ fetchPolicy: 'no-cache' })

  const isDashboardPage = router.pathname === Route.Dashboard.toString()

  return isDashboardPage ? (
    <DashboardAuthorizedNavbar
      unreadCount={unreadCount ? unreadCount.getUnreadCount : 0}
      className={className}
      notificationBar={notificationBar}
    />
  ) : (
    <LandingPageAuthorizedNavbar
      unreadCount={unreadCount ? unreadCount.getUnreadCount : 0}
      className={className}
      notificationBar={notificationBar}
    />
  )
}

export default AuthorizedNavbar
