import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { Route } from 'constants/common/routes'
import {
  CalendarIcon,
  CreditCardIcon,
  RunIcon,
  SettingsIcon,
  SignOutIcon,
  TicketIcon,
  UserIcon,
} from 'components/icons'
import { CrossIcon } from 'components/icons/CrossIcon'
import OffseasonLogo from 'components/icons/OffseasonLogo'
import { Button } from 'components/ui/button'
import { Sheet, SheetClose, SheetContent, SheetTrigger } from 'components/ui/sheet'

type DashboardAuthorizedUserDrawerProps = {
  isCustomer: boolean
  isReviewed: boolean
}

const DashboardAuthorizedUserDrawer = ({
  isCustomer,
  isReviewed,
}: DashboardAuthorizedUserDrawerProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { push } = useRouter()

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger>
        <UserIcon className="text-red hover:text-brand-mint w-8 h-8 xs:h-9 xs:w-9" />
      </SheetTrigger>
      <SheetContent side="top">
        <div className="relative w-full h-[100dvh] text-left text-base text-gray">
          <div className="h-full bg-white w-full overflow-hidden flex flex-col items-start justify-start">
            <div className="self-stretch bg-slate-900 h-[93.44px] flex items-center justify-between py-6 px-8">
              <OffseasonLogo className="w-[120px] h-[34px]" />
              <SheetClose>
                <CrossIcon className="text-white w-[36px] h-[36px]" />
              </SheetClose>
            </div>
            <div className="bg-slate-900 box-border w-full flex-1 overflow-hidden shrink-0 flex flex-col items-center justify-between px-6 pt-10 text-white border-t-[1px] border-solid border-slate-700">
              <div className="self-stretch flex flex-col items-start justify-start gap-8">
                <Button
                  disabled={!isReviewed && !isCustomer}
                  className="disabled:text-slate-700"
                  onClick={() => {
                    void push(isCustomer ? Route.MemberCalendar : Route.Calendar)
                  }}
                >
                  <div className="flex items-center gap-4 text-white text-lg">
                    <CalendarIcon className="h-11 w-11" />
                    <div>Calendar</div>
                  </div>
                </Button>
                <Button
                  disabled={!isReviewed && !isCustomer}
                  className="disabled:text-slate-700"
                  onClick={() => {
                    void push(isCustomer ? Route.MyMembershipsMember : Route.MyMembershipsAthlete)
                  }}
                >
                  <div className="flex items-center gap-4 text-white text-lg">
                    <TicketIcon className="h-11 w-11" />
                    <div>Memberships</div>
                  </div>
                </Button>
                {!isCustomer && (
                  <>
                    <Button
                      disabled={!isReviewed}
                      className="disabled:text-slate-700"
                      onClick={() => {
                        void push(Route.ProfileSettings)
                      }}
                    >
                      <div className="flex items-center gap-4 text-white text-lg">
                        <UserIcon className="h-11 w-11" />
                        <div>Profile settings</div>
                      </div>
                    </Button>
                    <Button
                      disabled={!isReviewed}
                      className="disabled:text-slate-700"
                      onClick={() => {
                        void push(Route.CoachingPreferences)
                      }}
                    >
                      <div className="flex items-center gap-4 text-white text-lg">
                        <RunIcon className="h-11 w-11" />
                        <div>Coaching preferences</div>
                      </div>
                    </Button>
                  </>
                )}
                {!isCustomer && (
                  <Button
                    disabled={!isReviewed}
                    className="disabled:text-slate-700"
                    onClick={() => {
                      void push('https://dashboard.stripe.com/login')
                    }}
                  >
                    <div className="flex items-center gap-4 text-white text-lg">
                      <CreditCardIcon className="h-11 w-11" />
                      <div>Stripe account</div>
                    </div>
                  </Button>
                )}
                <Button
                  disabled={!isReviewed && !isCustomer}
                  className="disabled:text-slate-700"
                  onClick={() => {
                    void push(
                      isCustomer ? Route.MemberAccountSettings : Route.AthleteAccountSettings,
                    )
                  }}
                >
                  <div className="flex items-center gap-4 text-white text-lg">
                    <SettingsIcon className="h-11 w-11" />
                    <div>Account settings</div>
                  </div>
                </Button>
                <Button
                  className="disabled:text-slate-700"
                  onClick={() => {
                    void signOut()
                  }}
                >
                  <div className="flex items-center gap-4 text-white text-lg">
                    <SignOutIcon className="h-11 w-11" />
                    <div>Sign out</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default DashboardAuthorizedUserDrawer
