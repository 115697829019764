import { useEffect, useRef, useState } from 'react'
import { useMedia } from 'react-use'
import { cn } from 'lib/utils'
import { useRouter } from 'next/router'
import { XIcon } from 'components/icons'
import { Button } from 'components/ui/button'

type NotificationBarProps = {
  text: string
  button?: { label: string; href: string }
  setNotificationHeight: (height: number) => void
}

export const NotificationBar = ({ text, button, setNotificationHeight }: NotificationBarProps) => {
  const [isShown, setIsShown] = useState(true)
  const { push } = useRouter()

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      setNotificationHeight(ref.current.offsetHeight)
    }
  }, [ref, setNotificationHeight])

  const isMaxSm = useMedia('(max-width: 640px)', false)

  return (
    <div
      ref={ref}
      className={cn(
        'bg-brand-mint relative px-4 py-1.5 flex items-center gap-2.5 justify-center z-[51]',
        isMaxSm && 'fixed bottom-4 right-4 rounded-lg left-4 h-fit py-3',
        isMaxSm && !isShown && 'hidden',
        !isMaxSm && 'top-0 sticky w-full',
      )}
    >
      <div>{text}</div>
      {button && (
        <Button
          className="font-semibold text-base underline underline-offset-2"
          variant="link"
          onClick={() => {
            void push(button.href)
          }}
        >
          {button?.label}
        </Button>
      )}
      {isMaxSm && (
        <button
          type="button"
          className="absolute top-1 p-1 right-1"
          onClick={() => setIsShown(false)}
        >
          <XIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  )
}
