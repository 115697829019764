import React from 'react'
import { useMedia } from 'react-use'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { FACEBOOK_URL, INSTAGRAM_URL } from 'constants/common'
import { Route } from 'constants/common/routes'
import ExternalLink from 'components/common/ExternalLink'
import { UserIcon } from 'components/icons'
import { FacebookColorfulIcon } from 'components/icons/FacebookColorfulIcon'
import { InstagramColorfulIcon } from 'components/icons/InstagramColorfulIcon'
import { Navbar } from 'components/navbar/Navbar'
import NavbarNavigation from 'components/navbar/NavbarNavigation'
import UnauthorizedNavbarMenu from 'components/navbar/UnauthorizedNavbarMenu'
import { buttonVariants } from 'components/ui/button'

type UnauthorizedNavbarProps = {
  className?: string
  notificationBar?: DatasourceItem[]
}

const UnauthorizedNavbar = ({ className, notificationBar }: UnauthorizedNavbarProps) => {
  const isMaxSm = useMedia('(max-width: 640px)', false)
  const isMax480px = useMedia('(max-width: 480px)', false)
  const isMax1540px = useMedia('(min-width: 1540px)', false)
  const isMax1715px = useMedia('(max-width: 1715px)', false)

  const isBetween1540And1715 = isMax1540px && isMax1715px

  return (
    <Navbar
      isSticky
      logoHref={Route.Base}
      className={className}
      navigation={<NavbarNavigation className="flex-1 hidden 2xl:flex" />}
      actionButtons={
        <div className={cn('flex gap-4 sm:gap-6 md:gap-8', isMax480px && 'gap-0')}>
          <div className="flex gap-4 items-center justify-center">
            <ExternalLink href={FACEBOOK_URL} className="hidden xl:flex">
              <FacebookColorfulIcon />
            </ExternalLink>
            <ExternalLink href={INSTAGRAM_URL}>
              <InstagramColorfulIcon />
            </ExternalLink>
          </div>
          <div className="hidden items-center justify-start xl:flex">
            <Link
              href={Route.SignIn}
              className={cn(buttonVariants({ variant: 'ghost', colorType: 'white' }), 'uppercase')}
            >
              <UserIcon />
              Sign in
            </Link>
          </div>
          <div className="flex gap-4">
            <div className="hidden flex-row items-start justify-start md:flex uppercase">
              <Link
                href={Route.CreateAccount}
                className={cn(
                  buttonVariants({ variant: 'outline', size: 'xl' }),
                  'uppercase font-semibold',
                )}
              >
                Become a coach
              </Link>
            </div>
            {!isMax480px && !isBetween1540And1715 && (
              <div className="flex flex-row items-start justify-start text-gray uppercase">
                <Link
                  href={Route.Athletes}
                  className={cn(
                    buttonVariants({ colorType: 'white', size: isMaxSm ? 'default' : 'xl' }),
                    'uppercase font-bold',
                  )}
                >
                  Book athlete
                </Link>
              </div>
            )}
          </div>
        </div>
      }
      menu={<UnauthorizedNavbarMenu />}
      notificationBar={notificationBar}
    />
  )
}

export default UnauthorizedNavbar
