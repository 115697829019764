import React from 'react'
import { useMedia } from 'react-use'
import { Role } from '@prisma/client'
import { cn } from 'lib/utils'
import Link from 'next/link'
import router from 'next/router'
import { useSession } from 'next-auth/react'
import { Route } from 'constants/common/routes'
import { LinkConfig } from 'components/navbar/constants'
import MobileNavigationMenu from 'components/navbar/MobileNavigationMenu'
import NavbarDropdownMenu from 'components/navbar/NavbarDropdownMenu'
import { buttonVariants } from 'components/ui/button'

const LandingPageAuthorizedNavbarMenu = () => {
  const isMaxSm = useMedia('(max-width: 640px)', false)
  const isSm = useMedia('(max-width: 767px)', false)
  const isMax2Xl = useMedia('(max-width: 1535px)', false)
  const { data } = useSession()
  const role = data?.user.role
  return isMaxSm ? (
    <MobileNavigationMenu
      links={LinkConfig}
      // eslint-disable-next-line react/no-unstable-nested-components
      buttons={(close) => (
        <div className="w-full flex flex-col items-center justify-center gap-4">
          {role === Role.Member && (
            <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
              <Link
                href={Route.Athletes}
                className={cn(
                  buttonVariants({ colorType: 'white', size: isSm ? 'lg' : 'xl' }),
                  'uppercase font-bold w-full',
                )}
                onClick={close}
              >
                Book Athlete
              </Link>
            </div>
          )}
        </div>
      )}
    />
  ) : (
    <NavbarDropdownMenu
      className="flex xl:hidden"
      actions={[
        {
          isVisible: isMax2Xl,
          name: 'Athletes',
          onClick: () => {
            void router.push(Route.Athletes)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'Clinics',
          onClick: () => {
            void router.push(Route.Clinics)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'How it works',
          onClick: () => {
            void router.push(Route.HowItWorks)
          },
        },
        {
          isVisible: isMax2Xl,
          name: 'Contact',
          onClick: () => {
            void router.push(Route.Contact)
          },
        },
        {
          isVisible: role === Role.Member && isMaxSm,
          name: 'Book athlete',
          onClick: () => {
            void router.push(Route.Athletes)
          },
        },
      ]}
    />
  )
}

export default LandingPageAuthorizedNavbarMenu
