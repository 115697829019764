import React, { FunctionComponent, useState } from 'react'
import { cn } from 'lib/utils'
import Image from 'next/image'
import Link from 'next/link'
import { FACEBOOK_URL, INSTAGRAM_URL } from 'constants/common'
import ExternalLink from 'components/common/ExternalLink'
import { CrossIcon } from 'components/icons/CrossIcon'
import { FacebookColorfulIcon } from 'components/icons/FacebookColorfulIcon'
import { MenuIcon } from 'components/icons/MenuIcon'
import OffseasonLogo from 'components/icons/OffseasonLogo'
import { LinkConfig } from 'components/navbar/constants'
import { buttonVariants } from 'components/ui/button'
import { Sheet, SheetClose, SheetContent, SheetTrigger } from 'components/ui/sheet'

type MobileNavigationMenuProps = {
  links?: typeof LinkConfig
  buttons?: (close: () => void) => React.ReactNode
}

const MobileNavigationMenu: FunctionComponent<MobileNavigationMenuProps> = ({ links, buttons }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger>
        <MenuIcon className="text-red hover:text-brand-mint h-8 w-8 xs:h-9 xs:w-9" />
      </SheetTrigger>
      <SheetContent side="top" onCloseAutoFocus={(event) => event.preventDefault()}>
        <div className="relative w-full h-[100dvh] text-left text-base text-gray">
          <div className="h-full bg-white w-full overflow-hidden flex flex-col items-start justify-start">
            <div className="self-stretch bg-slate-900 h-[93.44px] flex items-center justify-between py-6 px-8">
              <OffseasonLogo className="w-[120px] h-[34px]" />
              <SheetClose>
                <CrossIcon className="text-white w-[36px] h-[36px]" />
              </SheetClose>
            </div>
            <div className="bg-slate-900 box-border w-full flex-1 overflow-hidden shrink-0 flex flex-col items-center justify-between px-10 pt-10 text-white border-t-[1px] border-solid border-slate-700">
              <div className="self-stretch flex flex-col items-start justify-start gap-6">
                {links?.length && (
                  <>
                    {links.map(({ title, href }) => (
                      <Link
                        key={title}
                        className={cn(
                          buttonVariants({ variant: 'link', colorType: 'white' }),
                          'font-bold uppercase h-6',
                        )}
                        href={href}
                        onClick={() => {
                          setIsOpen(false)
                        }}
                      >
                        {title}
                      </Link>
                    ))}
                    <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-slate-700" />
                  </>
                )}
                {buttons?.(() => setIsOpen(false))}
              </div>
              <div className="flex flex-row items-center justify-start gap-[16px] mb-20">
                <ExternalLink href={FACEBOOK_URL}>
                  <FacebookColorfulIcon />
                </ExternalLink>
                <ExternalLink href={INSTAGRAM_URL}>
                  <Image
                    width={30}
                    height={30}
                    src="/images/instagram-icon.png"
                    alt="Instagram icon"
                  />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default MobileNavigationMenu
