import React from 'react'
import { MenuIcon } from 'components/icons/MenuIcon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'

type NavbarMenuAction = {
  name: string
  onClick: () => void
  isVisible?: boolean
}

type NavbarMenuProps = {
  className?: string
  actions: NavbarMenuAction[]
}

const NavbarMenu = ({ className, actions }: NavbarMenuProps) => (
  <div className={className}>
    <DropdownMenu>
      <DropdownMenuTrigger>
        <MenuIcon className="text-white hover:text-brand-mint" />
      </DropdownMenuTrigger>
      <DropdownMenuContent onCloseAutoFocus={(event) => event.preventDefault()}>
        {actions
          .filter(({ isVisible }) => isVisible)
          .map(({ name, onClick }) => (
            <DropdownMenuItem key={name} onClick={onClick}>
              {name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
)

export default NavbarMenu
