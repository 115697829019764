import React, { PropsWithChildren } from 'react'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { useSession } from 'next-auth/react'
import AuthorizedNavbar from 'components/navbar/AuthorizedNavbar'
import UnauthorizedNavbar from 'components/navbar/UnauthorizedNavbar'

type NavbarLayoutProps = {
  className?: string
  notificationBar?: DatasourceItem[]
}

const NavbarLayout = ({
  className,
  notificationBar,
  children,
}: PropsWithChildren<NavbarLayoutProps>) => {
  const user = useSession()

  const navbar =
    user.status === 'authenticated' ? (
      <AuthorizedNavbar className={className} notificationBar={notificationBar} />
    ) : (
      <UnauthorizedNavbar className={className} notificationBar={notificationBar} />
    )

  return (
    <div className="flex flex-col">
      {navbar}
      {children}
    </div>
  )
}

export default NavbarLayout
