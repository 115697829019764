import React from 'react'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { LinkConfig } from 'components/navbar/constants'
import { buttonVariants } from 'components/ui/button'

type NavbarNavigationProps = {
  className?: string
}

const NavbarNavigation = ({ className }: NavbarNavigationProps) => (
  <div className={cn('flex flex-row items-center justify-start gap-8 uppercase', className)}>
    {LinkConfig.map(({ title, href }) => (
      <Link
        key={title}
        className={buttonVariants({ variant: 'link', colorType: 'white' })}
        href={href}
      >
        {title}
      </Link>
    ))}
  </div>
)

export default NavbarNavigation
