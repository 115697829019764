import React, { useRef } from 'react'
import { useMedia } from 'react-use'
import { AthleteStatus, Role } from '@prisma/client'
import { useMeQuery } from 'apollo/generated/graphqlClient'
import { cn } from 'lib/utils'
import Link from 'next/link'
import router from 'next/router'
import { useSession } from 'next-auth/react'
import { OFFSEASON_SUPPORT_EMAIL } from 'constants/common'
import { Route } from 'constants/common/routes'
import ExternalLink from 'components/common/ExternalLink'
import { MessageIcon } from 'components/icons'
import { CONTACT_SECTION_ID } from 'components/landing-page/constants'
import MobileNavigationMenu from 'components/navbar/MobileNavigationMenu'
import NavbarMenu from 'components/navbar/NavbarDropdownMenu'
import { buttonVariants } from 'components/ui/button'
import { LinkConfig } from './constants'
import DashboardAuthorizedUserDrawer from './DashboardAuthorizedUserDrawer'
import DashboardAuthorizedUserMenu from './DashboardAutorizedUserMenu'

type DashboardAuthorizedNavbarMenuProps = {
  unreadCount: number
}

const DashboardAuthorizedNavbarMenu = ({ unreadCount }: DashboardAuthorizedNavbarMenuProps) => {
  const isMaxSm = useMedia('(max-width: 640px)', false)
  const isMaxLg = useMedia('(max-width: 1024px)', false)
  const isMax2Xl = useMedia('(max-width: 1535px)', false)
  const isSm = useMedia('(max-width: 767px)', false)
  const needHelpRef = useRef<HTMLAnchorElement>(null)

  const session = useSession()

  const { data } = useMeQuery({
    skip: session.data?.user.role !== Role.Athlete,
  })

  const isCustomer = !data?.me.athlete
  const isReviewed = data?.me.athlete.athleteStatus === AthleteStatus.reviewed

  return isMaxSm ? (
    <>
      <Link href={Route.Messages}>
        <MessageIcon
          hasNewMessage={!!unreadCount}
          className="hover:text-brand-mint h-8 w-8 xs:h-9 xs:w-9"
        />
      </Link>
      <DashboardAuthorizedUserDrawer isCustomer={isCustomer} isReviewed={isReviewed} />
      <MobileNavigationMenu
        // eslint-disable-next-line react/no-unstable-nested-components
        buttons={(close) => (
          <>
            <ExternalLink
              ref={needHelpRef}
              href={`mailto:${OFFSEASON_SUPPORT_EMAIL}?subject=Support`}
              className={cn(buttonVariants({ variant: 'link', colorType: 'white' }), 'self-center')}
              onClick={close}
            >
              Need help?
            </ExternalLink>
            <div className="self-stretch flex flex-col items-start justify-center gap-[16px]">
              <Link
                href={`/#${CONTACT_SECTION_ID}`}
                className={cn(
                  buttonVariants({ variant: 'outline', size: 'xl' }),
                  'uppercase font-semibold w-full',
                )}
                onClick={close}
              >
                Contact us
              </Link>

              <ExternalLink
                href={`mailto:${OFFSEASON_SUPPORT_EMAIL}?subject=Feedback`}
                className={cn(
                  buttonVariants({ colorType: 'white', size: isSm ? 'lg' : 'xl' }),
                  'uppercase font-bold w-full',
                )}
                onClick={close}
              >
                Give feedback
              </ExternalLink>
            </div>
          </>
        )}
        links={LinkConfig}
      />
    </>
  ) : (
    <>
      <Link href={Route.Messages}>
        <MessageIcon
          hasNewMessage={!!unreadCount}
          className="hover:text-brand-mint h-9 w-9 xl:hidden"
        />
      </Link>
      <DashboardAuthorizedUserMenu
        className="flex xl:hidden"
        isCustomer={isCustomer}
        isReviewed={isReviewed}
      />
      <NavbarMenu
        className="flex 2xl:hidden"
        actions={[
          {
            isVisible: isMaxLg,
            name: 'Need help?',
            onClick: () => {
              needHelpRef.current?.click()
            },
          },
          {
            isVisible: isMaxLg,
            name: 'Contact us',
            onClick: () => {
              void router.push('#contact')
            },
          },
          {
            isVisible: isMax2Xl,
            name: 'Athletes',
            onClick: () => {
              void router.push(Route.Athletes)
            },
          },
          {
            isVisible: isMax2Xl,
            name: 'Clinics',
            onClick: () => {
              void router.push(Route.Clinics)
            },
          },
          {
            isVisible: isMax2Xl,
            name: 'How it works',
            onClick: () => {
              void router.push(Route.HowItWorks)
            },
          },
          {
            isVisible: isMax2Xl,
            name: 'Contact',
            onClick: () => {
              void router.push(Route.Contact)
            },
          },
        ]}
      />
    </>
  )
}

export default DashboardAuthorizedNavbarMenu
