import React from 'react'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { UserIcon } from 'components/icons'

const NavbarUserButton = ({
  name,
  className,
  iconClassName,
}: {
  name?: string
  className?: string
  iconClassName?: string
}) => (
  <Link href={Route.Dashboard} className="flex items-center gap-1 hover:text-brand-mint">
    <UserIcon className={cn('h-8 w-8 xs:h-9 xs:w-9', iconClassName)} />
    <span className={cn('xl:inline-block hidden', className)}> {name}</span>
  </Link>
)
export default NavbarUserButton
