import { SVGProps } from 'react'

const OffseasonLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1956.18 545.343" {...props}>
    <defs>
      <clipPath id="a">
        <path d="M0 0h1956.185v545.343H0Z" />
      </clipPath>
    </defs>
    <g data-name="Layer 1">
      <path
        fill="#fff"
        d="M585.792 230.386h-36.308l-6.05 5.296L516.96 419.1l4.538 5.296h36.307l6.427-5.296 26.475-183.42Zm56.724-23.45-32.9 240.529-26.853 23.446h-96.815l-20.043-23.446 32.901-240.528 26.852-23.446h96.815ZM802.11 183.49l-6.432 46.519h-89.63l-10.587 73.743h66.18l-6.426 46.52H689.03l-17.02 120.639h-48.025l40.464-287.42ZM949.599 183.49l-6.431 46.519h-89.63l-10.587 73.743h66.18l-6.427 46.52H836.52L819.5 470.912h-48.025l40.464-287.42ZM1041.114 266.312l4.541-30.254-4.918-5.673h-36.685l-6.427 5.673-9.075 59.753 4.915 5.673h67.315l20.044 23.45-16.26 122.531-26.852 23.446h-97.569l-20.048-23.446 7.944-59.376h48.03l-4.919 30.635 4.92 5.673h36.684l6.427-5.673 10.21-65.049-4.914-5.673h-66.939l-20.424-23.449 15.887-117.617 26.847-23.446h97.573l20.044 23.446-7.944 59.376ZM1076.286 470.91l40.464-287.42h137.66l-6.43 46.519h-89.63l-10.21 71.476h66.56l-6.43 46.518h-66.558l-10.968 76.394h89.63l-6.432 46.514ZM1342.147 362.373l-6.431-96.815-33.656 96.815Zm2.648 46.514h-59l-21.555 62.024h-49.92l108.538-287.42h46.895l27.606 287.42h-48.408ZM1520.267 266.312l4.54-30.254-4.917-5.673h-36.685l-6.427 5.673-9.075 59.753 4.915 5.673h67.315l20.044 23.45-16.261 122.531-26.851 23.446h-97.57l-20.047-23.446 7.944-59.376h48.03l-4.92 30.635 4.92 5.673h36.685l6.427-5.673 10.21-65.049-4.914-5.673h-66.94l-20.423-23.449 15.887-117.617L1459 183.49h97.574l20.043 23.446-7.944 59.376ZM1679.106 230.386h-36.308l-6.05 5.296-26.474 183.419 4.537 5.296h36.308l6.427-5.296 26.475-183.42Zm56.724-23.45-32.901 240.529-26.852 23.446h-96.815l-20.043-23.446 32.9-240.528 26.853-23.446h96.815ZM1717.295 470.91l40.464-287.42h51.432l37.443 176.235 25.336-176.234H1920l-40.464 287.42h-51.433l-37.82-176.234-24.962 176.234Z"
      />
      <path
        fill="#67f1c8"
        d="m208.02 33.8-34.073 29.005-10.016 56.722 28.36 17.38H77.682L43.608 165.87 22.367 286.313l23.856 28.965h83.192l-12.23 69.514h-45.82l-5.753-7.04 3.702-20.999H9.937L0 413.273l23.856 28.965h83.192L93.974 516.38l23.854 28.964h119.64l34.073-28.964 10.097-57.366-27.314-16.775H367.767l34.114-28.965 22.287-126.478-23.856-29.004H317.12l11.103-62.916h45.337l5.753 7-3.62 20.516h59.819l9.977-56.52-23.857-28.965H338.44l13.074-74.1L327.66 33.8Zm18.989 64.487 7.723-6.518h44.855l5.833 6.518-6.8 38.62h-58.41Zm-17.017 96.588h58.412L257.3 257.791h-58.41ZM87.98 250.792l8.649-48.918 8.247-6.999h45.78l-11.103 62.916H93.772Zm219.002 64.486h45.338l5.792 7-9.815 55.474-8.247 7.04h-45.297Zm-118.23 0h58.41l-12.228 69.514h-58.412ZM159.506 481.34l6.878-39.102h58.412l-6.88 39.102-8.165 6.517h-44.895Z"
      />
      <g clipPath="url(#a)">
        <path
          fill="#67f1c8"
          d="M483.033 34.758c-9.44 4.895-18.88 10.49-27.622 16.083-8.04 4.895-16.083 10.14-23.076 16.084-.699.35-3.147 1.748-2.098 2.098 47.551-11.888 95.102-23.776 147.2-31.118.35-19.23-10.84-27.622-28.322-26.573-24.475 2.098-45.803 12.587-66.082 23.426m229.714-8.39c11.538 0 32.167.348 32.866 11.537.35 5.244-3.846 9.44-9.44 8.741-2.448-2.098 1.748-5.245 1.398-7.343-1.398-2.797-10.839-3.146-17.132-3.496-20.279-1.399-40.558 1.05-58.04 2.797-26.573 2.098-50.698 5.944-73.425 8.741-2.447 23.776-9.44 44.754-16.083 65.033-6.644 19.932-13.986 39.857-20.629 60.49-6.643 19.58-13.636 40.558-20.629 60.837-3.147 9.437-6.643 19.227-10.839 29.37-2.098 4.542-3.496 9.436-6.643 13.632-1.049 1.053-2.797 3.85-5.944 4.55-2.797.345-5.594 0-6.293-2.798-1.049-3.496 4.545-6.293 5.943-8.745 2.098-3.496 3.497-8.39 4.895-13.286 10.49-31.114 20.28-64.68 30.77-95.102 6.642-18.527 12.935-36.363 18.53-54.192 5.944-18.182 12.237-36.713 15.034-57.34-43.006 6.292-84.613 16.082-125.87 26.571-5.944 1.05-13.636 3.497-20.979 4.546-7.342 1.748-17.132 2.097-18.18-4.196-1.4-6.294 6.293-12.587 10.838-16.084 5.594-4.195 10.84-8.04 15.734-10.838 11.188-7.693 22.377-13.986 32.866-20.28 11.188-6.293 23.775-12.587 35.663-17.482C520.095 6.787 534.78 1.193 549.814.144c26.223-1.749 38.81 12.587 39.86 35.663 37.411-3.496 79.368-10.489 123.073-9.44"
        />
        <path
          fill="#67f1c8"
          d="M615.193 129.513c3.496-4.55 8.042-10.142 14.335-10.142 10.84 0 10.49 13.638 16.433 19.932 15.734-3.496 25.174-11.19 40.21-17.482 3.845-1.75 13.285-4.547 13.285 1.744 0 3.85-6.992 5.594-11.188 8.046-9.44 4.895-15.384 9.09-24.475 13.632-4.196 2.45-9.09 4.895-12.937 5.948-13.636 4.195-18.53-6.993-22.377-16.437-8.74 9.79-14.335 22.03-22.377 33.565-3.496 4.549-12.237 11.888-16.782 3.85-3.147-4.895-.7-15.738.35-22.377 3.496-22.031 8.391-39.162 15.383-59.79 7.343-21.328 11.888-36.013 20.63-54.894 2.097-5.244 6.293-15.734 11.188-16.083 7.342-.35 4.195 6.293 2.797 10.839-1.049 3.146-1.399 5.943-2.098 8.04-1.049 2.099-1.748 2.099-2.797 3.847-1.748 3.497-3.147 8.042-4.545 12.238-10.14 27.97-18.182 57.34-25.175 89.156 3.497-3.143 6.294-8.737 10.14-13.632"
        />
        <path
          fill="#67f1c8"
          d="M695.607 138.603c8.39-10.143 18.18-19.232 23.776-31.819 0-.35 0-.7-.35-.35-5.245 3.497-9.79 8.741-13.636 14.335-4.196 5.247-8.042 11.187-9.79 17.834m-1.4 3.496c-.698 1.045-.348 3.843 0 4.895 9.092.7 16.435-3.15 23.078-6.293 13.985-6.294 25.873-11.888 40.908-18.88 2.447-1.052 5.943-3.849 10.139-4.898 4.196-1.398 9.79-1.398 10.489 2.448.35 3.848-4.195 4.547-6.992 5.592-3.147 1.053-6.294 2.797-9.091 4.55-15.035 8.037-29.37 14.684-46.153 22.722-6.293 2.797-12.936 6.647-20.628 6.647-9.091-.353-13.986-5.948-13.287-16.083 1.049-14.685 19.23-37.763 29.72-44.057 6.293-3.846 17.132-4.545 17.482 5.594.349 6.643-4.895 12.238-7.692 16.783-2.448 3.145-4.546 5.942-6.993 8.74-4.895 5.593-10.49 10.143-15.035 16.083-3.146-.346-4.895-1.745-5.944-3.843"
        />
        <path
          fill="#fff"
          d="M1902.852 445.433h-8.445v-5.973h24.17v5.973h-8.585v25.478h-7.14ZM1948.766 458.872c-.14-3.78-.28-8.352-.28-12.925h-.14a169.98 169.98 0 0 1-3.5 12.179l-3.826 12.27h-5.553l-3.36-12.177c-1.026-3.686-2.1-8.165-2.846-12.272h-.093c-.186 4.246-.327 9.099-.56 13.019l-.56 11.945h-6.58l2.007-31.45h9.473l3.08 10.499c.979 3.64 1.959 7.559 2.659 11.245h.14c.886-3.64 1.96-7.792 2.986-11.292l3.36-10.452h9.285l1.727 31.45h-6.952Z"
        />
      </g>
    </g>
  </svg>
)

export default OffseasonLogo
