import { ReactNode, useState } from 'react'
import { useMedia } from 'react-use'
import { DatasourceItem } from 'api/integrations/storyblok/datasources/types'
import { useScrollDirection } from 'hooks/useScrollDirection'
import { cn, shouldNotificationBarBeShown } from 'lib/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import OffseasonLogo from 'components/icons/OffseasonLogo'
import { NotificationBar } from './NotificationBar'

type NavbarProps = {
  className?: string
  navigation?: ReactNode
  actionButtons: ReactNode
  isSticky?: boolean
  menu: ReactNode
  logoHref: Route
  notificationBar?: DatasourceItem[]
}

export const Navbar = ({
  className,
  navigation,
  actionButtons,
  menu,
  isSticky,
  logoHref,
  notificationBar,
}: NavbarProps) => {
  const { pathname } = useRouter()
  const [notificationHeight, setNotificationHeight] = useState(0)

  const scrollDirection = useScrollDirection()

  const isNotificationBarActive =
    notificationBar?.find((item) => item.name === 'isActive')?.value === 'yes'
  const text = notificationBar?.find((item) => item.name === 'text')?.value
  const buttonLabel = notificationBar?.find((item) => item.name === 'buttonLabel')?.value
  const buttonHref = notificationBar?.find((item) => item.name === 'buttonLink')?.value
  const isNotificationBarShown = isNotificationBarActive && shouldNotificationBarBeShown(pathname)
  const isButtonPresent = buttonLabel && buttonHref

  const isMaxSm = useMedia('(max-width: 640px)', false)

  return (
    <>
      {isNotificationBarShown && (
        <NotificationBar
          text={text || ''}
          setNotificationHeight={setNotificationHeight}
          {...(isButtonPresent && { button: { label: buttonLabel, href: buttonHref } })}
        />
      )}
      <div
        className={cn(
          isSticky && scrollDirection === 'up' ? 'sticky' : '',
          `z-50 bg-slate-900 w-full lg:h-28 h-24 flex sm:gap-16 gap-4 flex-row items-center justify-between py-6 px-6 xs:px-8 lg:px-14 2xl:px-36 xl:px-24 box-border text-center text-base text-white border-b-[1px] border-slate-700`,
          className,
        )}
        style={{
          top: isNotificationBarShown && !isMaxSm ? `${notificationHeight}px` : '0',
        }}
      >
        <Link href={logoHref}>
          <OffseasonLogo className="lg:w-[220px] w-[140px]" />
        </Link>
        {navigation}
        <div className="shrink-0 flex flex-row items-center justify-start gap-4 text-left">
          {actionButtons}
          {menu}
        </div>
      </div>
    </>
  )
}
