import { SVGProps } from 'react'
import { cn } from 'lib/utils'

type MessageIconProps = SVGProps<SVGSVGElement> & { hasNewMessage?: boolean }

export const MessageIcon = ({ hasNewMessage, ...props }: MessageIconProps) => (
  <div className="relative">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 11V11.01M8 11V11.01M16 11V11.01M4 21V8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5H17C17.7956 5 18.5587 5.31607 19.1213 5.87868C19.6839 6.44129 20 7.20435 20 8V14C20 14.7956 19.6839 15.5587 19.1213 16.1213C18.5587 16.6839 17.7956 17 17 17H8L4 21Z"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <div
      className={cn(
        'bg-red-500 scale-0 rounded-full h-[40%] w-[40%] absolute -top-0 -right-0 duration-300 ease-in-out',
        hasNewMessage && 'scale-100',
      )}
    />
  </div>
)
